import React, { useEffect } from "react";
import "../OurService/service.css";
import ServiceCard from "./ServiceCard";
import SecondCard from "./SecondCard";
import Meta from "../Meta";

const Service = () => {
  return (
    <div>
      <Meta
        title="PHP Framework and CMS Development Services- Wordpress, WooCommerce, Magento, Shopify."
        description="I am Fahim Full Stack Web Developer, Get affordable website and eCommerce development services from India. Fahim is one of the highly experienced PHP developer for Magento, Shopify or any php platform. 24X7 call @+91 9718925775"
        keywords="Part, Time, Website, Maintenance, Developer, Freelancer, PHP, WordPress, Magento, Opencart, Shopify, Codeigniter, Laravel, Symfony, GraphQL, Flights, Hotel, Bus, Bank all types of 3rd party APIs integration Work From Home, in Delhi, India. Call #+91 9718925775"
      />
      <div className="service-image">
        <h1>Our-Service</h1>
      </div>
      <ServiceCard />
      <SecondCard />
    </div>
  );
};

export default Service;
