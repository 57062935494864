import React, { useEffect } from "react";
import ResumeHero from "./ResumeHero";
import ResumeCard from "./ResumeCard";
import ResumeSection from "./ResumeSection";
import ResumeMain from "./ResumeMain";
import Meta from "../Meta";

const Resume = () => {
  return (
    <div>
      <Meta
        title="12+ Years Experienced PHP Developer in Delhi NCR, India"
        description="I am Fahim Full Stack Web Developer, Know about Fahim- A very talented PHP developer. We have delivered 1000+ PHP Projects on various platforms. 24X7 call @+91 9718925775"
        keywords="Part, Time, Website, Maintenance, Developer, Freelancer, PHP, WordPress, Magento, Opencart, Shopify, Codeigniter, Laravel, Symfony, GraphQL, Flights, Hotel, Bus, Bank all types of 3rd party APIs integration Work From Home, in Delhi, India. Call #+91 9718925775"
      />
      <ResumeHero />
      <ResumeCard />
      <ResumeSection />
      <ResumeMain />
    </div>
  );
};

export default Resume;
