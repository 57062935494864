import React from 'react';
import '../Ourportfolio/Port.css'
import Magmain from './Magmain';


const Mag = () => {
  const images = [
    "https://i.ibb.co/ZNLysng/wheelrack.jpg",// <--47-->
    "https://i.ibb.co/brSjrxZ/july.jpg",// <--48-->
   "https://i.ibb.co/TqrdQw1/glamourbook.jpg",// <--49-->
    "https://i.ibb.co/gT3kF7z/cognionuae.jpg",// <--50-->
    "https://i.ibb.co/WWrzVBH/blushan.jpg",// <--51-->
    "https://i.ibb.co/wcFTS0m/iphon.jpg",// <--52-->
    "https://i.ibb.co/SBd0DkD/miniclub.jpg",// <--53-->
    "https://i.ibb.co/S3cd1jz/evok.jpg",// <--54-->
    "https://i.ibb.co/7NXPBtj/floraindia.jpg",// <--55-->
    "https://i.ibb.co/ZL8gQf1/andaazfashion.jpg",// <--56-->
    "https://i.ibb.co/pPG67XS/modestforever.jpg",// <--57-->
    "https://i.ibb.co/DMN8zML/probusindia.jpg",// <--58-->
    "https://i.ibb.co/4VzjrYN/craft.jpg",// <--59-->
    "https://i.ibb.co/417B2XN/mobi-e.jpg",// <--60-->
    "https://i.ibb.co/QrcD1vW/ishalife.jpg",// <--61-->
  ]
  const cardsData = [
    {
      imageSrc: images[0],
      paragraphText: " car wheel accessories",
      Moreinfo: "https://wheelrack.ca/  ",
    },
    {
      imageSrc: images[1],
      paragraphText: "marketplace multiple vendor",
      Moreinfo: "https://www.aquariumspecialty.com/ ",
    },

    {
      imageSrc: images[2],
      paragraphText: "react.js  PWA for beauty brand",
      Moreinfo: "https://glamourbook.com/",
    },
    {
      imageSrc: images[3],
      paragraphText: "bathroom home accessories ",
      Moreinfo: "https://cognitionuae.com/  ",
    },
    {
      imageSrc: images[4],
      paragraphText: "baby items",
      Moreinfo: "https://blushandbloom.ae/  ",
    },
    {
      imageSrc: images[5],
      paragraphText: "mobile accessories",
      Moreinfo: "https://www.myimaginestore.com/ ",
    },

    {
      imageSrc: images[6],
      paragraphText: "baby cloth",
      Moreinfo: "https://miniklub.in/ ",
    },
    {
      imageSrc: images[7],
      paragraphText: "furniture kitchen accessories",
      Moreinfo: "https://www.evok.in/  ",
    },
    {
      imageSrc: images[8],
      paragraphText: "flowers",
      Moreinfo: "https://www.floraindia.com/  ",
    },
    {
      imageSrc: images[9],
      paragraphText: "women cloth",
      Moreinfo: "https://www.andaazfashion.com/",
    },

    {
      imageSrc: images[10],
      paragraphText: " women Muslim cloth",
      Moreinfo: "https://www.modestforever.com/ ",
    },
    {
      imageSrc: images[11],
      paragraphText: "Bag",
      Moreinfo: "https://probusindia.com/  ",
    },
    {
      imageSrc: images[12],
      paragraphText: "women cloth",
      Moreinfo: "https://www.craftsvilla.com/  ",
    },
    {
      imageSrc: images[13],
      paragraphText: "mobile accessories",
      Moreinfo: "https://www.mobileciti.com.au/",
    },
    {
      imageSrc: images[14],
      paragraphText: "eCommerce",
      Moreinfo: "https://www.ishalife.com/in/",
    }
  ];


  return (
    <div className="hoxx">
      {cardsData.map((card, index) => (
        <Magmain
          key={index}
          imageSrc={card.imageSrc}
          paragraphText={card.paragraphText}
          Moreinfo={card.Moreinfo}
          images={images}
        />
      ))}
    </div>
  );
};




export default Mag;
