import React from "react";
import "../Footer/Footer.css";
import { FaLocationDot } from "react-icons/fa6";
import { IoMdCall } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import { CiMail } from "react-icons/ci";
import { FaSkype } from "react-icons/fa6";
import TestimonialCarousel from "../include/Testimonial"
import FacebookPageEmbed from "../../Pages/Facebook";
import { NavLink, Link } from "react-router-dom";
import LazyLoad from "react-lazyload";

const Footer = () => {
  return (
    <>
      <TestimonialCarousel />
      <footer>
        <div className="footer_inner_main">
          <div className="container">
            <div className="footer_inner_items">
              <div className="footer_inner_item item_one">
                <h5>About Us</h5>
                <div className="widget_container">
                  <p>
                    My name is Fahim Shakir. In the discipline of Web Development and Design I am full stack prominent as PHP Programmer / Web Developer / Web Freelancer. I am reputated by my outstanding work, excellent programming skills and versatile nature of program coding with Software and Website Development.

                    I am full stack developed many web based applications. I am persistent in developing the web application as well as dynamic websites <Link to="/about-us.php" className="reed">Readmore</Link></p>
                </div>
              </div>
              <div className="footer_inner_item item_two">
                <h5>Contact Us</h5>
                <div className="widget_container">
                  <ul className="icon_list_items">
                    <li className="icon_list_item">
                      <span className="icon_list_icon">
                        <svg aria-hidden="true" className="e-font-icon-svg e-fas-map-marker-alt" viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg">
                          <path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path>
                        </svg>
                      </span>
                      <span className="icon_list_text">Block C-11B, Top Floor, New Ashok Nagar,</span>
                    </li>
                    <li className="icon_list_item">
                      <span className="icon_list_icon">
                        <svg aria-hidden="true" className="e-font-icon-svg e-fas-map-marker-alt" viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg">
                          <path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path>
                        </svg>
                      </span>
                      <span className="icon_list_text">Delhi NCR 110096, India</span>
                    </li>
                    <li className="icon_list_item">
                      <span className="icon_list_icon">
                        <svg aria-hidden="true" className="e-font-icon-svg e-fas-phone-alt" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                          <path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path>
                        </svg>
                      </span>
                      <span className="icon_list_text"><a href="tel:919718925775">09718925775</a> </span>
                    </li>

                    <li className="icon_list_item">
                      <FaWhatsapp />
                      <span className="icon_list_text"><a href="https://wa.me/919268751849">09268751849</a> </span>
                    </li>

                    <li className="icon_list_item">
                      <span className="icon_list_icon">
                        <svg aria-hidden="true" className="e-font-icon-svg e-far-envelope" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                          <path d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"></path>
                        </svg>
                      </span>
                      <span className="icon_list_text"><a href="mailto:fahimshakir786@gmail.com">fahimshakir786@gmail.com</a></span>
                    </li>
                    <li className="icon_list_item">
                      <span className="icon_list_icon">
                        <svg aria-hidden="true" className="e-font-icon-svg e-far-envelope" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                          <path d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"></path>
                        </svg>
                      </span>
                      <span className="icon_list_text"><a href="mailto:info@fahimshakir.com">info@fahimshakir.com</a></span>
                    </li>
                    <li className="icon_list_item">
                      <span className="icon_list_icon">
                        <svg aria-hidden="true" className="e-font-icon-svg e-fab-skype" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
                          <path d="M424.7 299.8c2.9-14 4.7-28.9 4.7-43.8 0-113.5-91.9-205.3-205.3-205.3-14.9 0-29.7 1.7-43.8 4.7C161.3 40.7 137.7 32 112 32 50.2 32 0 82.2 0 144c0 25.7 8.7 49.3 23.3 68.2-2.9 14-4.7 28.9-4.7 43.8 0 113.5 91.9 205.3 205.3 205.3 14.9 0 29.7-1.7 43.8-4.7 19 14.6 42.6 23.3 68.2 23.3 61.8 0 112-50.2 112-112 .1-25.6-8.6-49.2-23.2-68.1zm-194.6 91.5c-65.6 0-120.5-29.2-120.5-65 0-16 9-30.6 29.5-30.6 31.2 0 34.1 44.9 88.1 44.9 25.7 0 42.3-11.4 42.3-26.3 0-18.7-16-21.6-42-28-62.5-15.4-117.8-22-117.8-87.2 0-59.2 58.6-81.1 109.1-81.1 55.1 0 110.8 21.9 110.8 55.4 0 16.9-11.4 31.8-30.3 31.8-28.3 0-29.2-33.5-75-33.5-25.7 0-42 7-42 22.5 0 19.8 20.8 21.8 69.1 33 41.4 9.3 90.7 26.8 90.7 77.6 0 59.1-57.1 86.5-112 86.5z"></path>
                        </svg>
                      </span>
                      <span className="icon_list_text"><a href="skype:fahim.shakir40"> fahim.shakir40</a></span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer_inner_item item_three">
                <h5>Usefull Links</h5>
                <div className="widget_container">
                  <ul className="icon_list_items">
                    <li className="icon_list_item">
                      <span className="icon_list_text">
                        <Link to="/about-us.php">ABOUT US</Link>
                      </span>
                    </li>
                    <li className="icon_list_item">
                      <span className="icon_list_text">
                        <Link to="/resume.php">My RESUME</Link>
                      </span>
                    </li>
                    <li className="icon_list_item">
                      <span className="icon_list_text">
                        <Link to="/services.php">OUR SERVICES</Link>
                      </span>
                    </li>
                    <li className="icon_list_item">
                      <span className="icon_list_text">
                        <Link to="/portfolio.php">OUR PORTFOLIO</Link>
                      </span>
                    </li>
                    <li className="icon_list_item">
                      <span className="icon_list_text">
                        <a href="https://www.fahimshakir.com/blog/">BLOG</a>
                      </span>
                    </li>
                    <li className="icon_list_item">
                      <span className="icon_list_text">
                        <Link to="/contact.php">CONTACT US</Link>
                      </span>
                    </li>
                    <li className="icon_list_item">
                      <span className="icon_list_text">
                        <Link to="/privacy-policy.php">Privacy Policy</Link>
                      </span>
                    </li>
                    <li className="icon_list_item">
                      <span className="icon_list_text">
                        <Link to="/terms-and-conditions.php">Terms & Conditions</Link>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer_inner_item item_four">
                <LazyLoad height={300} offset={100} once>
                <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FFahimShakirWebsiteDeveloper%2F%3Fref%3Dembed_page&tabs=timeline&width=340&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="300" height="300" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                </LazyLoad>
              </div>
            </div>
          </div>
          <div className="copyright_section">
            <p>Copyright © 2024 <a href="https://www.fahimshakir.com/">Fahim Shakir Web Developer</a> All rights reserved.</p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
