import React from "react";
import Slider from "react-slick";
import diwaker from "../image/diwakr.webp";
import imran from "../image/imran.webp";
import hershita from "../image/harshita.webp";
import YoutubeSlider from "./YoutubeSlider";
import PortCard from "../../Pages/Ourportfolio/PortCard";
import { NavLink, Link } from "react-router-dom";


const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow custom-next" onClick={onClick}>
      ❱
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow custom-prev" onClick={onClick}>
      ❰
    </div>
  );
};

export default function SimpleSlider() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  return (
    <>
      <div className="our-portfolio">
        <div className="container p-0">
          <h3 className="heading-titletext-center sec-review-ti">We Love Our Works</h3>
          <p className="des-choose">
            2000+ awesome projects delivered in 50+ nations earned us the respect
            we proudly cherish today. We love what we do and that makes our work
            as diverse as you possibly expect from the best web design &
            development company.
          </p>
         

        </div>
        <PortCard />

      <Link to="/portfolio.php" className="viewhere">View All Project</Link>

      <div className="testimonialsection">
        <div className="container">
          <div className="row">
              <div className="col-md-12">
                  <h3 className="text-center">What our clients have to say</h3>
                  <p>Happy Customer</p>
                  <hr className="small" />
              </div>
          </div>
          <div className="container margin uniqe">
            <Slider {...settings}>
              <div className="align-center">
                <img src={hershita} alt="hershita" className="img-circle" width='100px' height='100px'/>
                <p className="experience">
                  Great experience working on my personal website development
                  project with Fahim Shakir Web Developer. He was professional and
                  creative. Their dedicated approach to the work is commendable. I
                  got user-friendly and incredible looking website within the
                  stipulated time outstanding Ecommerce Website Designing and
                  development. Thanks for giving my brand and my business great
                  web presence and an added online edge.
                </p>
              </div>
              <div className="align-center">
                <img src={imran} alt="imran"  width='100px' height='100px'/>
                <p className="experience">
                  Excellent Quality Highly recommends A+++ +, Fahim Shakir is a
                  Superb web developer.
                </p>
              </div>
              <div className="align-center">
                <img src={diwaker} alt="diwaker"  width='100px' height='100px'/>
                <p className="experience">
                  One of the best Magento developer I have met. He help me out
                  from several typical problems. We have also worked together for
                  several projects.
                </p>
              </div>
            </Slider>
          </div>
          </div>
        <YoutubeSlider />
        </div>
        </div>
    </>
  );
}
