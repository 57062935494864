import React from "react";
import "../Ourportfolio/Port.css";
import Appmain from "./Appmain";

const AppMo = () => {
  const images = [
    "https://i.ibb.co/c3Q510k/glamour.jpg",
    "https://i.ibb.co/G26yS6V/jamawealth.jpg",
    "https://i.ibb.co/0VrYrTX/realestate.jpg",
  ];

  const cardsData = [
    {
      imageSrc: images[0],
      paragraphText: "Glamour Book",
      Moreinfo:
      "https://play.google.com/store/apps/details?id=com.altlab.glamourbook&pli=1 ",
    },
    {
      imageSrc: images[1],
      paragraphText: "JAMA",
      Moreinfo: "https://play.google.com/store/apps/details?id=in.jama.app ",
    },
    {
      imageSrc: images[2],
      paragraphText: "Real Estate",
      Moreinfo: "https://play.google.com/store/apps/details?id=es.roid.and.trovit ",
    }
  ];

  return (
    <div className="hoxx">
      {cardsData.map((card, index) => (
        <Appmain
          key={index}
          imageSrc={card.imageSrc}
          paragraphText={card.paragraphText}
          Moreinfo={card.Moreinfo}
          images={images}
        />
      ))}
    </div>
  );
};
export default AppMo;
