import React, { useEffect } from 'react';
import '../Ourportfolio/Main.css'
import MainPortfolio from './MainPortfolio'
import Meta from '../Meta';

const Portfolio = () => {
  return (
    <div>
      <Meta
        title="Portfolio- Web and eCommerce Development"
        description="I am Fahim Full Stack Web Developer, Check our web, eCommerce and digital marketing portfolio. We have delivered small to big projects all over the globe. 24X7 call @+91 9718925775"
        keywords="Part, Time, Website, Maintenance, Developer, Freelancer, PHP, WordPress, Magento, Opencart, Shopify, Codeigniter, Laravel, Symfony, GraphQL, Flights, Hotel, Bus, Bank all types of 3rd party APIs integration Work From Home, in Delhi, India. Call #+91 9718925775"
      />
    <div className='main-port'>
        <h1 className='head-port'>OUR PORTFOLIO</h1>
        <h2 className='head2port'>I'm a kibitzer with a broad portfolio. </h2>
        <h3 className='inno-head2'>- Our Innovative Creations - </h3>
        <p className='discipline'>My name is Fahim Shakir Sidiqui. In the discipline of Web Development and Design I am prominent as PHP Programmer / Web Developer / Web Freelancer. I am reputated by my outstanding work, excellent programming skills and versatile nature of program coding with Software and Website Development.
           Read More Sign up for special offers</p>  
    </div>
    <MainPortfolio/>
    </div>
  )
}

export default Portfolio