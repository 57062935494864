import React, { useState, useEffect } from "react";

import "../assets/css/index.css";
import Meta from "./Meta";
import HeroSlider from "../components/include/HeroSlider";
import Card from "../components/include/Card";
import Technology from "../components/include/Technology";
import LetsWork from "../components/include/LetsWork";
import BalanceTime from "../components/include/BalanceTime";
import Chooseus from "../components/include/Chooseus";
import CountSection from "../components/include/CountSection";
import Portfolio from "../components/include/Portfolio";
import LazyLoad from "react-lazyload";


const Home = () => {
   
  return (
    <div>
      <Meta
        title="Part Time and Full Time Freelance Web Developer in Delhi NCR,India"
        description="I am Fahim Full Stack Web Developer, Planning for website or eCommerce development for your business? Highly ezpeirenced PHP developer available according to customized hiring model. 24X7 call @+91 9718925775"
        keywords="Part, Time, Website, Maintenance, Developer, Freelancer, PHP, WordPress, Magento, Opencart, Shopify, Codeigniter, Laravel, Symfony, GraphQL, Flights, Hotel, Bus, Bank all types of 3rd party APIs integration Work From Home, in Delhi, India. Call #+91 9718925775"
      />

      <HeroSlider />
      <Card />
      <Technology />
      <LetsWork />
      <BalanceTime></BalanceTime>
      <Portfolio></Portfolio>
      <Chooseus></Chooseus>
      <CountSection></CountSection>

    </div>
  );
};

export default Home;
