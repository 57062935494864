import React from 'react';
import '../Ourportfolio/Port.css'
import Phpmain from './Phpmain';


const Php = () => {
  const images = [
    "https://i.ibb.co/hKgK6NL/durgical.jpg",// <--1-->
    "https://i.ibb.co/wrvHPm6/pboplus.jpg",// <--2-->
    "https://i.ibb.co/nDPtnN4/laxmi.jpg",// <--3-->
    "https://i.ibb.co/FJ34yCf/craftsilva.jpg",// <--4-->
  ]
  const cardsData = [
    {
      imageSrc: images[0],
      paragraphText: 'Surgicalsystems',
      Moreinfo: "http://surgicalsystems.in/",
    },
    {
      imageSrc: images[1],
        paragraphText: 'pboplus',
        Moreinfo: "https://www.pboplus.com/",
      },
      {
        imageSrc: images[2],
        paragraphText: 'laxmiflourmill',
        Moreinfo: "https://www.laxmiflourmill.com/",
      },
      {
        imageSrc: images[3],
        paragraphText: 'craftsvilla',
        Moreinfo: "https://www.craftsvilla.com/",
      }

  ];


  return (
    <div className="hoxx">
    {cardsData.map((card, index) => (
      <Phpmain
        key={index}
        imageSrc={card.imageSrc}
        paragraphText={card.paragraphText}
        Moreinfo={card.Moreinfo}
        images={images}
      />
    ))}
  </div>
  );
};




export default Php;
