import React, { useState } from "react";
import "../Ourportfolio/Port.css";
import Allmain from "./Allmain";

const All = () => {
  const images = [
    "https://i.ibb.co/hKgK6NL/durgical.jpg",
    "https://i.ibb.co/wrvHPm6/pboplus.jpg",
    "https://i.ibb.co/nDPtnN4/laxmi.jpg",
    "https://i.ibb.co/FJ34yCf/craftsilva.jpg",
    "https://i.ibb.co/NpDRcdr/ytviews.jpg",
    "https://i.ibb.co/dmZ02pp/fitfhome.jpg",
    "https://i.ibb.co/k3jtCJx/blueprint.jpg",
    "https://i.ibb.co/vwbcL4L/math.jpg",
    "https://i.ibb.co/Jd3JfpN/athletenex.jpg",
    "https://i.ibb.co/VTjnqfG/venwiz.jpg",
    "https://i.ibb.co/GMt3zXg/entrack.jpg",
    "https://i.ibb.co/YXpJ66m/iberiaskinbrand.jpg",
    "https://i.ibb.co/XD6V05Y/pallettucksuk.jpg",
    "https://i.ibb.co/R3tQzf2/labelleza.jpg",
    "https://i.ibb.co/jMhVg5g/Screenshot-2024-07-02-183439.jpg",
    "https://i.ibb.co/k6QrpFL/cuber.jpg",
    "https://i.ibb.co/D16McrG/Screenshot-2024-07-02-202159.jpg",
    "https://i.ibb.co/ZNLysng/wheelrack.jpg",
    "https://i.ibb.co/brSjrxZ/july.jpg",
    "https://i.ibb.co/TqrdQw1/glamourbook.jpg",
    "https://i.ibb.co/gT3kF7z/cognionuae.jpg",
    "https://i.ibb.co/WWrzVBH/blushan.jpg",
    "https://i.ibb.co/wcFTS0m/iphon.jpg",
    "https://i.ibb.co/SBd0DkD/miniclub.jpg",
    "https://i.ibb.co/S3cd1jz/evok.jpg",
    "https://i.ibb.co/7NXPBtj/floraindia.jpg",
    "https://i.ibb.co/ZL8gQf1/andaazfashion.jpg",
    "https://i.ibb.co/pPG67XS/modestforever.jpg",
    "https://i.ibb.co/DMN8zML/probusindia.jpg",
    "https://i.ibb.co/4VzjrYN/craft.jpg",
    "https://i.ibb.co/417B2XN/mobi-e.jpg",
    "https://i.ibb.co/QrcD1vW/ishalife.jpg",
    "https://i.ibb.co/ydm8HFG/Screenshot-2024-07-03-121031.jpg",
    "https://i.ibb.co/f45V2ww/Screenshot-2024-07-02-153921.jpg",
    "https://i.ibb.co/4jvXgbP/Screenshot-2024-07-02-154045.jpg",
    "https://i.ibb.co/nsjbtyX/Screenshot-2024-07-02-154205.jpg",
    "https://i.ibb.co/dgY8BXc/Screenshot-2024-07-02-154243.jpg",
    "https://i.ibb.co/gTQBhwg/Screenshot-2024-07-02-154321.jpg",
    "https://i.ibb.co/r0BgrXM/Screenshot-2024-07-02-154401.jpg",
    "https://i.ibb.co/Zc0X1P7/Screenshot-2024-07-02-154506.jpg",
    "https://i.ibb.co/vhkHNs1/Screenshot-2024-07-02-154553.jpg",
    "https://i.ibb.co/tq730S4/Screenshot-2024-07-02-154633.jpg",
    "https://i.ibb.co/GdynyNj/Screenshot-2024-07-02-154706.jpg",
    "https://i.ibb.co/QQxsb7F/Screenshot-2024-07-02-154802.jpg",
    "https://i.ibb.co/j4GZpwt/Screenshot-2024-07-02-154910.jpg",
    "https://i.ibb.co/4FRbKdF/Screenshot-2024-07-03-121716.jpg",
    "https://i.ibb.co/3WhGHp8/Screenshot-2024-07-03-121826.jpg",
    "https://i.ibb.co/PGDPXxM/easywayportal.jpg",
    "https://i.ibb.co/J36vmj4/Screenshot-2024-07-02-214348.jpg",// <--47-->
    "https://i.ibb.co/HY1RrRH/Screenshot-2024-07-02-214419.jpg",// <--48-->
    "https://i.ibb.co/c3Q510k/glamour.jpg",
    "https://i.ibb.co/G26yS6V/jamawealth.jpg",
    "https://i.ibb.co/0VrYrTX/realestate.jpg",
    "https://i.ibb.co/ZT9D5fr/viny.jpg",// <--47-->
    "https://i.ibb.co/1Qk7W4r/gififestt.jpg",// <--48-->
    "https://i.ibb.co/fX2gkWr/Screenshot-2024-07-03-125129.jpg",// <--47-->
    "https://i.ibb.co/kXN32Tn/Screenshot-2024-07-03-125155.jpg",// <--48-->
    "https://i.ibb.co/Dt2v2Nz/pushmycart.jpg",// <--47-->
  ];
  const cardsData = [
    {
      imageSrc: images[0],
      paragraphText: "Surgicalsystems",
      Moreinfo: "http://surgicalsystems.in/",
    },
    {
      imageSrc: images[1],
      paragraphText: 'pboplus',
      Moreinfo: "https://www.pboplus.com/",
    },

    {
      imageSrc: images[2],
        paragraphText: 'laxmiflourmill',
        Moreinfo: "https://www.laxmiflourmill.com/",
    },
    {
      imageSrc: images[3],
      paragraphText: 'craftsvilla',
        Moreinfo: "https://www.craftsvilla.com/",
    },  
    // php
    {
      imageSrc: images[4],
      paragraphText: "3rd party APIs based",
      Moreinfo: "https://www.ytviews.in/ ",
    },
    {
      imageSrc: images[5],
      paragraphText: "subscription based",
      Moreinfo: "https://fitfrhome.com/",
    },
    {
      imageSrc: images[6],
      paragraphText: "AI based",
      Moreinfo: "https://blueprint8.com/cooking-redefined/",
    },
    {
      imageSrc: images[7],
      paragraphText: "AI based",
      Moreinfo: "https://math.gradeninja.ai/effortlessly/",
    },
    {
      imageSrc: images[8],
      paragraphText: "AI based",
      Moreinfo: "https://athletenex.com/ai-tool/ ",
    },
    {
      imageSrc: images[9],
      paragraphText: "3rd party APIs based",
      Moreinfo: "https://www.venwiz.com/vendors/",
    },
    {
      imageSrc: images[10],
      paragraphText: "News",
      Moreinfo: "https://entrackr.com/ ",
    },
    {
      imageSrc: images[11],
      paragraphText: "Beauty products",
      Moreinfo: "https://iberiaskinbrands.in/",
    },

    {
      imageSrc: images[12],
      paragraphText: "electronic machine products",
      Moreinfo: "https://www.pallettrucksuk.co.uk/",
    },
    {
      imageSrc: images[13],
      paragraphText: "birthday cake",
      Moreinfo: "https://www.labelleza.co.in/",
    },
    {
      imageSrc: images[14],
      paragraphText: "School",
      Moreinfo: "https://ajcampus.com/ ",
    },
    {
      imageSrc: images[15],
      paragraphText: "LMS eLearning",
      Moreinfo: "https://cybersectrainings.com/ ",
    },
    // word
    {
      imageSrc: images[16],
      paragraphText: 'BIOCONNEXUS',
      Moreinfo: "https://bioconnexus.com/",
    },
    // joom
    {
      imageSrc: images[17],
      paragraphText: " car wheel accessories",
      Moreinfo: "https://wheelrack.ca/  ",
    },
    {
      imageSrc: images[18],
      paragraphText: "marketplace multiple vendor",
      Moreinfo: "https://www.aquariumspecialty.com/ ",
    },
    {
      imageSrc: images[19],
      paragraphText: "react.js  PWA for beauty brand",
      Moreinfo: "https://glamourbook.com/",
    },
    {
      imageSrc: images[20],
      paragraphText: "bathroom home accessories ",
      Moreinfo: "https://cognitionuae.com/  ",
    },
    {
      imageSrc: images[21],
      paragraphText: "baby items",
      Moreinfo: "https://blushandbloom.ae/  ",
    },
    {
      imageSrc: images[22],
      paragraphText: "mobile accessories",
      Moreinfo: "https://www.myimaginestore.com/ ",
    },
    {
      imageSrc: images[23],
      paragraphText: "baby cloth",
      Moreinfo: "https://miniklub.in/ ",
    },
    {
      imageSrc: images[24],
      paragraphText: "furniture kitchen accessories",
      Moreinfo: "https://www.evok.in/  ",
    },
    {
      imageSrc: images[25],
      paragraphText: "flowers",
      Moreinfo: "https://www.floraindia.com/  ",
    },
    {
      imageSrc: images[26],
      paragraphText: "women cloth",
      Moreinfo: "https://www.andaazfashion.com/",
    },
    {
      imageSrc: images[27],
      paragraphText: " women Muslim cloth",
      Moreinfo: "https://www.modestforever.com/ ",
    },
    {
      imageSrc: images[28],
      paragraphText: "Bag",
      Moreinfo: "https://probusindia.com/  ",
    },
    {
      imageSrc: images[29],
      paragraphText: "women cloth",
      Moreinfo: "https://www.craftsvilla.com/  ",
    },
    {
      imageSrc: images[30],
      paragraphText: "mobile accessories",
      Moreinfo: "https://www.mobileciti.com.au/",
    },
    {
      imageSrc: images[31],
      paragraphText: "eCommerce",
      Moreinfo: "https://www.ishalife.com/in/",
    },
// mag
    {
      imageSrc: images[32],
      paragraphText: "OFFICEFORCE",
      Moreinfo: "https://www.officeforce.in/ ",
    },
    // open
    {
      imageSrc: images[33],
      paragraphText: "Mobile cover",
      Moreinfo: "https://miglowstore.in/",
    },
    {
      imageSrc: images[34],
      paragraphText: "premium shoes",
      Moreinfo: "https://www.superglamourous.it/",
    },
    {
      imageSrc: images[35],
      paragraphText: "Beauty products",
      Moreinfo: "https://www.phlaboratories.in/",
    },
    {
      imageSrc: images[36],
      paragraphText: "medicine products",
      Moreinfo: "https://planet-herbs.com/",
    },
    {
      imageSrc: images[37],
      paragraphText: "Beauty products",
      Moreinfo: "https://lojadodivo.com/ ",
    },
    {
      imageSrc: images[38],
      paragraphText: "Dog products",
      Moreinfo: "https://www.silidog.com/",
    },
    {
      imageSrc: images[39],
      paragraphText: "Beauty products",
      Moreinfo: "https://www.newu.in/ ",
    },
    {
      imageSrc: images[40],
      paragraphText: "Baby products",
      Moreinfo: "https://pureborn.com/  ",
    },
    {
      imageSrc: images[41],
      paragraphText: "Beauty products",
      Moreinfo: "https://www.gkhair.co.in/ ",
    },
    {
      imageSrc: images[42],
      paragraphText: "men products",
      Moreinfo: "https://bummer.in/",
    },
    {
      imageSrc: images[43],
      paragraphText: "women products",
      Moreinfo: "https://www.andcircus.com/ ",
    },
    {
      imageSrc: images[44],
      paragraphText: "Bathroom products",
      Moreinfo: "https://www.pebblegrey.co.uk/",
    },
    // shopi
    {
      imageSrc: images[45],
      paragraphText: "TASSHS",
      Moreinfo: "http://tasshs.com/",
    },
    {
      imageSrc: images[46],
      paragraphText: "FILIPINO",
      Moreinfo: "https://filipino4u.com/",
    },
    {
      imageSrc: images[47],
      paragraphText: "EASYWAYPORTAL",
      Moreinfo: "http://www.easywayportal.com/",
    },
// code
    {
      imageSrc: images[48],
      paragraphText: " gardenhills",
      Moreinfo: "https://gardenhills.ge/ ",
    },
    {
      imageSrc: images[49],
      paragraphText: "cointelegraph",
      Moreinfo: "https://cointelegraph.com/ ",
    },
    // Laravel
    {
      imageSrc: images[50],
      paragraphText: "Glamour Book",
      Moreinfo: "https://play.google.com/store/apps/details?id=com.altlab.glamourbook&pli=1 ",
    },
    {
      imageSrc: images[51],
      paragraphText: "JAMA",
      Moreinfo: "https://play.google.com/store/apps/details?id=in.jama.app ",
    },

    {
      imageSrc: images[52],
      paragraphText: "Real Estate",
      Moreinfo: "https://play.google.com/store/apps/details?id=es.roid.and.trovit ",
    },
    // appmo
    {
      imageSrc: images[53],
      paragraphText: " letteringonthecheap",
      Moreinfo: "https://www.letteringonthecheap.com/ ",
    },
    {
      imageSrc: images[54],
      paragraphText: "giftfest",
      Moreinfo: "https://giftfest.ro/",
    },
    // Angular
    {
      imageSrc: images[55],
      paragraphText: " A classic momery gam",
      Moreinfo: "https://itunes.apple.com/us/app/memory-game/id1110008808?ls=1  ",
    },

    {
      imageSrc: images[56],
      paragraphText: "off line map India city",
      Moreinfo: "https://apps.apple.com/us/app/gogps/id1113451417?amp%3Bmt=8&ls=1",
    },
    // ios
    {
      imageSrc: images[57],
      paragraphText: "pushmycart",
      Moreinfo: "https://www.pushmycart.com/index.php ",
    }
  ];


  return (
    <div className="hoxx">
      {cardsData.map((card, index) => (
        <Allmain
          key={index}
          imageSrc={card.imageSrc}
          paragraphText={card.paragraphText}
          Moreinfo={card.Moreinfo}
          images={images}
        />
      ))}
    </div>
  );
};

export default All;
