import { useEffect } from "react";

const Meta = ({ title, description, keywords, canonical }) => {
  useEffect(() => {
    document.title = title;

    const setMetaTag = (name, content) => {
      let element = document.querySelector(`meta[name="${name}"]`);
      if (element) {
        element.setAttribute("content", content);
      } else {
        element = document.createElement("meta");
        element.name = name;
        element.content = content;
        document.head.appendChild(element);
      }
    };

    const setCanonicalLink = (href) => {
      let link = document.querySelector('link[rel="canonical"]');
      if (link) {
        link.setAttribute("href", href);
      } else {
        link = document.createElement("link");
        link.rel = "canonical";
        link.href = href;
        document.head.appendChild(link);
      }
    };

    setMetaTag("description", description);
    setMetaTag("keywords", keywords);
    setCanonicalLink(canonical || window.location.href);
  }, [title, description, keywords, canonical]);

  return null;
};

export default Meta;
