import React from "react";
import "../Ourportfolio/Port.css";
import Openmain from "./Openmain";

const Opencart = () => {
  const images = [
    "https://i.ibb.co/ydm8HFG/Screenshot-2024-07-03-121031.jpg",
  ];
  const cardsData = [
    {
      imageSrc: images[0],
      paragraphText: "OFFICEFORCE",
      Moreinfo: "https://www.officeforce.in/ ",
    }
  ];

  return (
    <div className="hoxx">
      {cardsData.map((card, index) => (
        <Openmain
          key={index}
          imageSrc={card.imageSrc}
          paragraphText={card.paragraphText}
          Moreinfo={card.Moreinfo}
          images={images}
        />
      ))}
    </div>
  );
};

export default Opencart;
