import React from "react";
import "../Ourportfolio/Port.css";
import Allmain from "./Allmain";
import Shopimain from "./Shopimain";

const Shopi = () => {
  const images = [
    "https://i.ibb.co/f45V2ww/Screenshot-2024-07-02-153921.jpg",
    "https://i.ibb.co/4jvXgbP/Screenshot-2024-07-02-154045.jpg",
    "https://i.ibb.co/nsjbtyX/Screenshot-2024-07-02-154205.jpg",
    "https://i.ibb.co/dgY8BXc/Screenshot-2024-07-02-154243.jpg",
    "https://i.ibb.co/gTQBhwg/Screenshot-2024-07-02-154321.jpg",
    "https://i.ibb.co/r0BgrXM/Screenshot-2024-07-02-154401.jpg",
    "https://i.ibb.co/Zc0X1P7/Screenshot-2024-07-02-154506.jpg",
    "https://i.ibb.co/vhkHNs1/Screenshot-2024-07-02-154553.jpg",
    "https://i.ibb.co/tq730S4/Screenshot-2024-07-02-154633.jpg",
    "https://i.ibb.co/GdynyNj/Screenshot-2024-07-02-154706.jpg",
    "https://i.ibb.co/QQxsb7F/Screenshot-2024-07-02-154802.jpg",
    "https://i.ibb.co/j4GZpwt/Screenshot-2024-07-02-154910.jpg",
  ];
  const cardsData = [
    {
      imageSrc: images[0],
      paragraphText: "Mobile cover",
      Moreinfo: "https://miglowstore.in/",
    },
    {
      imageSrc: images[1],
      paragraphText: "premium shoes",
      Moreinfo: "https://www.superglamourous.it/",
    },
    {
      imageSrc: images[2],
      paragraphText: "Beauty products",
      Moreinfo: "https://www.phlaboratories.in/",
    },

    {
      imageSrc: images[3],
      paragraphText: "medicine products",
      Moreinfo: "https://planet-herbs.com/",
    },
    {
      imageSrc: images[4],
      paragraphText: "Beauty products",
      Moreinfo: "https://lojadodivo.com/ ",
    },
    {
      imageSrc: images[5],
      paragraphText: "Dog products",
      Moreinfo: "https://www.silidog.com/",
    },
    {
      imageSrc: images[6],
      paragraphText: "Beauty products",
      Moreinfo: "https://www.newu.in/ ",
    },
    {
      imageSrc: images[7],
      paragraphText: "Baby products",
      Moreinfo: "https://pureborn.com/  ",
    },
    {
      imageSrc: images[8],
      paragraphText: "Beauty products",
      Moreinfo: "https://www.gkhair.co.in/ ",
    },
    {
      imageSrc: images[9],
      paragraphText: "men products",
      Moreinfo: "https://bummer.in/",
    },
    {
      imageSrc: images[10],
      paragraphText: "women products",
      Moreinfo: "https://www.andcircus.com/ ",
    },
    {
      imageSrc: images[11],
      paragraphText: "Bathroom products",
      Moreinfo: "https://www.pebblegrey.co.uk/",
    },
   
  ];

  return (
    <div className="hoxx">
    {cardsData.map((card, index) => (
      <Shopimain
        key={index}
        imageSrc={card.imageSrc}
        paragraphText={card.paragraphText}
        Moreinfo={card.Moreinfo}
        images={images}
      />
    ))}
  </div>
  );
};

export default Shopi;
