import React, { useEffect } from 'react';
import AboutHero from "./AboutHero";
import Meta from '../Meta';

const About = () => {
  return (
  <div>
    <Meta
     title="Highly Experienced PHP Developer-Mangento, Shopify and  WordPress Experts"
     description="I am Fahim Full Stack Web Developer, About Fahim Shakir - A exceptional PHP hero who delivered 1000+ php platform projects. All are happy clients, you can check the Google ratings. 24X7 call @+91 9718925775"
     keywords="Part, Time, Website, Maintenance, Developer, Freelancer, PHP, WordPress, Magento, Opencart, Shopify, Codeigniter, Laravel, Symfony, GraphQL, Flights, Hotel, Bus, Bank all types of 3rd party APIs integration Work From Home, in Delhi, India. Call #+91 9718925775"
    />
  <AboutHero/>
  </div>
  );
};

export default About;
