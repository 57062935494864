import React, { useEffect } from 'react';
import  hrline from '../../components/image/hr-line.png'
import '../Contact/Contact.css'
import ContactCard from './ContactCard';
import ContactForm from './ContactForm';
import Meta from '../Meta';

const Contact = () => {

  return (
    <div>
      <Meta
      title="Contact Us for Affordable all types of Website and eCommerce Development"
      description="I am Fahim Full Stack Web Developer, Planning to develop web or eCommerce website, contact us today. 24X7 call @+91 9718925775"
      keywords="Part, Time, Website, Maintenance, Developer, Freelancer, PHP, WordPress, Magento, Opencart, Shopify, Codeigniter, Laravel, Symfony, GraphQL, Flights, Hotel, Bus, Bank all types of 3rd party APIs integration Work From Home, in Delhi, India. Call #+91 9718925775"
      />
    <div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7006.316500194394!2d77.30685900000002!3d28.595029!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce48c3fffffff%3A0x6f083fc47a4f5e6b!2sFahim%20Website%20Developer.!5e0!3m2!1sen!2sus!4v1719895979490!5m2!1sen!2sus"
        width="100%"
        height="450"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
        <div className='tell'>
          <h1 className='contact'>Contact us and tell us what you think</h1>
          <img src={hrline} alt="hrline" loading="lazy"/>
        </div>

        <ContactCard/>
        <ContactForm/>
    </div>
  );
};

export default Contact;
