import React from 'react';
import '../Ourportfolio/Port.css'
import Laravelmain from './Laravelmain';


const Laravel = () => {
  const images = [
    "https://i.ibb.co/J36vmj4/Screenshot-2024-07-02-214348.jpg",// <--47-->
    "https://i.ibb.co/HY1RrRH/Screenshot-2024-07-02-214419.jpg",// <--48-->
  ]
  const cardsData = [
    {
      imageSrc: images[0],
      paragraphText: " gardenhills",
      Moreinfo: "https://gardenhills.ge/ ",
    },
    {
      imageSrc: images[1],
      paragraphText: "cointelegraph",
      Moreinfo: "https://cointelegraph.com/ ",
    }
  ];


  return (
    <div className="hoxx">
      {cardsData.map((card, index) => (
        <Laravelmain
          key={index}
          imageSrc={card.imageSrc}
          paragraphText={card.paragraphText}
          Moreinfo={card.Moreinfo}
          images={images}
        />
      ))}
    </div>
  );
};




export default Laravel;
