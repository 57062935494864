import React from "react";
import "../Ourportfolio/Port.css";
import Wordmain from "./Woedmain";

const Word = () => {
  const images = [
    "https://i.ibb.co/NpDRcdr/ytviews.jpg", // <--16-->
    "https://i.ibb.co/dmZ02pp/fitfhome.jpg", // <--17-->
    "https://i.ibb.co/k3jtCJx/blueprint.jpg", // <--18-->
    "https://i.ibb.co/vwbcL4L/math.jpg", // <--19-->
    "https://i.ibb.co/Jd3JfpN/athletenex.jpg", // <--20-->
    "https://i.ibb.co/VTjnqfG/venwiz.jpg", // <--21-->
    "https://i.ibb.co/GMt3zXg/entrack.jpg", // <--22-->
    "https://i.ibb.co/YXpJ66m/iberiaskinbrand.jpg", // <--23-->
    "https://i.ibb.co/XD6V05Y/pallettucksuk.jpg", // <--24-->
    "https://i.ibb.co/R3tQzf2/labelleza.jpg", // <--25-->
    "https://i.ibb.co/jMhVg5g/Screenshot-2024-07-02-183439.jpg", // <--26-->
    "https://i.ibb.co/k6QrpFL/cuber.jpg", // <--27-->
  ];
  const cardsData = [
    {
      imageSrc: images[0],
      paragraphText: "WooCommerce 3rd party APIs based",
      Moreinfo: "https://www.ytviews.in/ ",
    },
    {
      imageSrc: images[1],
      paragraphText: "WooCommerce subscription based",
      Moreinfo: "https://fitfrhome.com/",
    },
    {
      imageSrc: images[2],
      paragraphText: "AI based",
      Moreinfo: "https://blueprint8.com/cooking-redefined/",
    },
    {
      imageSrc: images[3],
      paragraphText: "AI based",
      Moreinfo: "https://math.gradeninja.ai/effortlessly/",
    },
    {
      imageSrc: images[4],
      paragraphText: "AI based",
      Moreinfo: "https://athletenex.com/ai-tool/ ",
    },
    {
      imageSrc: images[5],
      paragraphText: "3rd party APIs based",
      Moreinfo: "https://www.venwiz.com/vendors/",
    },
    {
      imageSrc: images[6],
      paragraphText: "News",
      Moreinfo: "https://entrackr.com/ ",
    },
    {
      imageSrc: images[7],
      paragraphText: "Beauty products",
      Moreinfo: "https://iberiaskinbrands.in/",
    },
    {
      imageSrc: images[8],
      paragraphText: "electronic machine products",
      Moreinfo: "https://www.pallettrucksuk.co.uk/",
    },
    {
      imageSrc: images[9],
      paragraphText: "birthday cake",
      Moreinfo: "https://www.labelleza.co.in/",
    },
    {
      imageSrc: images[10],
      paragraphText: "School",
      Moreinfo: "https://ajcampus.com/ ",
    },
    {
      imageSrc: images[11],
      paragraphText: "LMS eLearning",
      Moreinfo: "https://cybersectrainings.com/ ",
    },
  ];

  return (
    <div className="hoxx">
      {cardsData.map((card, index) => (
        <Wordmain
          key={index}
          imageSrc={card.imageSrc}
          paragraphText={card.paragraphText}
          Moreinfo={card.Moreinfo}
          images={images}
        />
      ))}
    </div>
  );
};

export default Word;
