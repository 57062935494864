// import React, { useState } from "react";
// import "react-image-lightbox/style.css";
// import Lightbox from "react-image-lightbox";
// import "../Ourportfolio/All.css";

// const Allmain = ({ imageSrc, paragraphText, Moreinfo, images }) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [photoIndex, setPhotoIndex] = useState(0);

//   const handleLinkClick = (url, e) => {
//     e.preventDefault();
//     window.open(url, "_blank", "noopener,noreferrer");
//   };

//   const handleViewLargerClick = (e) => {
//     e.preventDefault();
//     setIsOpen(true);
//   };

//   return (
//     <div className="over">
//       <div className="All">
//         <img
//           src={imageSrc}
//           alt="Card image"
//           style={{ cursor: "default" }} loading="lazy"/>
//         <div className="maininfo">
//           <a
//             href={Moreinfo}
//             className="orinfo"
//             onClick={(e) => handleLinkClick(Moreinfo, e)} >
//             More info
//           </a>
//           <a
//             href="/"
//             className="orinfo"
//             onClick={handleViewLargerClick}
//           >
//             View Larger
//           </a>
//         </div>
//       </div>
//       <p className="newhouse">{paragraphText}</p>

//       {isOpen && (
//         <Lightbox
//           mainSrc={images[photoIndex]}
//           nextSrc={images[(photoIndex + 1) % images.length]}
//           prevSrc={images[(photoIndex + images.length - 1) % images.length]}
//           onCloseRequest={() => setIsOpen(false)}
//           onMovePrevRequest={() =>
//             setPhotoIndex((photoIndex + images.length - 1) % images.length)
//           }
//           onMoveNextRequest={() =>
//             setPhotoIndex((photoIndex + 1) % images.length)
//           }
//         />
//       )}
//     </div>
//   );
// };

// export default Allmain;


// Allmain.js
// Allmain.js
// Allmain.js
import React, { useState, useEffect } from "react";
import "react-image-lightbox/style.css";
import Lightbox from "react-image-lightbox";
import "../Ourportfolio/All.css";
import SkeletonLoader from "./SkeletonLoader"; // Adjust the path as needed

const Allmain = ({ imageSrc, paragraphText, Moreinfo, images }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [contentLoaded, setContentLoaded] = useState(false);

  useEffect(() => {
    // Simulate a 3-second delay for content loading
    const timer = setTimeout(() => {
      setContentLoaded(true);
    }, 3000);

    // Cleanup the timer
    return () => clearTimeout(timer);
  }, []);

  const handleLinkClick = (url, e) => {
    e.preventDefault();
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleViewLargerClick = (e) => {
    e.preventDefault();
    setIsOpen(true);
  };

  return (
    <div className="over">
      <div className="All">
        {!contentLoaded && <SkeletonLoader width="100%" height="300px" />}
        {contentLoaded && (
          <img
            src={imageSrc}
            alt="Card image"
            style={{ display: "block", cursor: "default" }}
            loading="lazy"
          />
        )}
        <div className="maininfo">
          <a
            href={Moreinfo}
            className="orinfo"
            onClick={(e) => handleLinkClick(Moreinfo, e)} >
            More info
          </a>
          <a
            href="/"
            className="orinfo"
            onClick={handleViewLargerClick}
          >
            View Larger
          </a>
        </div>
      </div>
      {!contentLoaded ? (
        <SkeletonLoader width="100%" height="20px" />
      ) : (
        <p className="newhouse">{paragraphText}</p>
      )}

      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </div>
  );
};

export default Allmain;

